/*
	Generated on 29/05/2024 by UI Generator PRICES-IDE
	https://amanah.cs.ui.ac.id/research/ifml-regen
	version 3.3.2
*/
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { useAuth } from 'commons/auth'
import { Button, Modal } from 'commons/components'
import isSelectedFeature from 'commons/utils/isSelectedFeature'
import { isMobile } from 'commons/utils/responsive'

import * as Layouts from 'commons/layouts'

const TarikDanaTable = ({ viaPGListElement }) => {
  const { checkPermission } = useAuth()
  const navigate = useNavigate()
  const detail = async viaPaymentGatewayItem => {
    isMobile() && navigate(`/disbursement/detail/${viaPaymentGatewayItem.id}`)
  }

  return (
    <Layouts.ListComponentTableLayout
      items={[viaPGListElement]}
      detail={detail}
      itemsAttrs={[
        {
          id: 'disbursementId',
          condition: 'isHeading',
          label: 'ID',
          featureName: 'disbursementId',
        },
        {
          id: 'disbursementMethod',
          condition: 'isHeading',
          label: 'Disbursement Method',
          featureName: 'disbursementMethod',
        },
        {
          id: 'status',
          condition: 'isHeading',
          label: 'Status',
          featureName: 'status',
        },
        {
          id: 'amount',
          condition: 'isHeading',
          label: 'Amount',
          featureName: 'amount',
        },
      ]}
      itemsEvents={viaPaymentGatewayItem => [
        <Link to={`/disbursement/detail/${viaPaymentGatewayItem.id}`}>
          <Button variant="primary">Detail</Button>
        </Link>,
      ]}
      itemsModals={viaPaymentGatewayItem => []}
    />
  )
}

export default TarikDanaTable
