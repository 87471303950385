/*
    Generated on 29/05/2024 by UI Generator PRICES-IDE
    https://amanah.cs.ui.ac.id/research/ifml-regen
    version 3.3.2
*/
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import {
  Button,
  Form,
  SelectionField,
  MultiSelectionField,
  InputField,
  MultiSelectField,
  TextAreaField,
  VisualizationAttr,
  Spinner,
} from 'commons/components'
import {
  ALLOWED_PERMISSIONS,
  findAllowedPermission,
} from 'commons/constants/allowedPermission'
import cleanFormData from 'commons/utils/cleanFormData'

import saveViaDisbursement from '../services/saveViaDisbursement'

import { ToasterError } from 'commons/components'
import * as Layouts from 'commons/layouts'

const FormInternationalMoneyTransfer = ({}) => {
  const { control, handleSubmit } = useForm()

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')
  const disbursementMethod = searchParams.get('disbursementMethod')

  const kirim = data => {
    const cleanData = cleanFormData(data)
    console.log(disbursementMethod)
    saveViaDisbursement({
      ...cleanData,
      ...{ idprogram: id },
      ...{ disbursementMethod: disbursementMethod },
    })
      .then(({ data: { data } }) => {
        navigate(`/disbursement/list`)
      })
      .catch(error => {
        console.error(error)
        toast.error(t => <ToasterError error={error} t={t} />)
      })
  }

  return (
    <Layouts.FormComponentLayout
      title="International Money Transfer"
      onSubmit={handleSubmit(kirim)}
      vas={[]}
      formFields={[
        <Controller
          name="beneficiary_account_number"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Nomor Rekening"
              placeholder="Masukkan nomor rekening"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="beneficiary_bank_name"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Bank"
              placeholder="Masukkan nama bank"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="amount"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Jumlah dana"
              placeholder="Masukkan jumlah dana"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="description"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Description"
              placeholder="Masukkan description"
              fieldState={fieldState}
              {...field}
              isRequired={false}
              var
            />
          )}
        />,
        <Controller
          name="sender_name"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Nama Pengirim"
              placeholder="Masukkan nama pengirim"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="sender_country"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Kode Negara Pengirim"
              placeholder="Masukkan kode negara pengirim"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="sender_job"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Pekerjaan pengirim"
              placeholder="Masukkan pekerjaan pengirim"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="vendor_name"
          control={control}
          render={({ field, fieldState }) => (
            <SelectionField
              label="Vendor"
              placeholder="Masukkan Vendor Disbursement"
              options={[{ id: 'Flip', name: 'Flip' }]}
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="source_country"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Kode Negara Asal"
              placeholder="Masukkan kode negara asal"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="destination_country"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Kode Negara Tujuan"
              placeholder="Masukkan kode negara tujuan"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="beneficiary_full_name"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Nama Lengkap Penerima"
              placeholder="Masukkan nama lengkap penerima"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="beneficiary_bank_id"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="ID Bank Penerima"
              placeholder="Masukkan ID bank penerima"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="beneficiary_email"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Email Penerima"
              placeholder="Masukkan email penerima"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="beneficiary_msisdn"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Nomor Telepon Penerima"
              placeholder="Masukkan nomor telepon penerima"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="beneficiary_nationality"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Kewarganegaraan Penerima"
              placeholder="Masukkan kewarganegaraan penerima"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="beneficiary_province"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Provinsi Penerima"
              placeholder="Masukkan provinsi penerima"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="beneficiary_city"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Kota Penerima"
              placeholder="Masukkan kota penerima"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="beneficiary_postal_code"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Kode Pos Penerima"
              placeholder="Masukkan kode pos penerima"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="beneficiary_relationship"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Hubungan dengan Penerima"
              placeholder="Masukkan hubungan dengan penerima"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="beneficiary_source_of_funds"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Sumber Dana Penerima"
              placeholder="Masukkan sumber dana penerima"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="beneficiary_remittance_purposes"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Tujuan Pengiriman Dana"
              placeholder="Masukkan tujuan pengiriman dana"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="beneficiary_sort_code"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Kode Sort Penerima"
              placeholder="Masukkan kode sort penerima"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="sender_place_of_birth"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Tempat Lahir Pengirim"
              placeholder="Masukkan tempat lahir pengirim"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="sender_date_of_birth"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Tanggal Lahir Pengirim"
              placeholder="Masukkan tanggal lahir pengirim"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="sender_address"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Alamat Pengirim"
              placeholder="Masukkan alamat pengirim"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="sender_identity_type"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Jenis Identitas Pengirim"
              placeholder="Masukkan jenis identitas pengirim"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="sender_identity_number"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Nomor Identitas Pengirim"
              placeholder="Masukkan nomor identitas pengirim"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="sender_email"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Email Pengirim"
              placeholder="Masukkan email pengirim"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="sender_city"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Kota Pengirim"
              placeholder="Masukkan kota pengirim"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="sender_phone_number"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Nomor Telepon Pengirim"
              placeholder="Masukkan nomor telepon pengirim"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="beneficiary_region"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Region Penerima"
              placeholder="Masukkan region penerima"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="transaction_type"
          control={control}
          render={({ field, fieldState }) => (
            <SelectionField
              label="Tipe Transaksi"
              placeholder="Pilih tipe transaksi"
              options={[
                { id: 'C2C', name: 'C2C' },
                { id: 'B2B', name: 'B2B' },
                { id: 'B2C', name: 'B2C' },
              ]}
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
      ]}
      itemsEvents={[
        <Button type="submit" variant="primary">
          Kirim
        </Button>,
      ]}
    />
  )
}

export default FormInternationalMoneyTransfer
