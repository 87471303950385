/*
    Generated on 29/05/2024 by UI Generator PRICES-IDE
    https://amanah.cs.ui.ac.id/research/ifml-regen
    version 3.3.2
*/
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import {
  Button,
  Form,
  SelectionField,
  MultiSelectionField,
  InputField,
  MultiSelectField,
  TextAreaField,
  VisualizationAttr,
  Spinner,
} from 'commons/components'
import {
  ALLOWED_PERMISSIONS,
  findAllowedPermission,
} from 'commons/constants/allowedPermission'
import cleanFormData from 'commons/utils/cleanFormData'

import saveViaDisbursement from '../services/saveViaDisbursement'

import { ToasterError } from 'commons/components'
import * as Layouts from 'commons/layouts'

const FormAgentMoneyTransfer = ({}) => {
  const { control, handleSubmit } = useForm()

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')
  const disbursementMethod = searchParams.get('disbursementMethod')

  const kirim = data => {
    const cleanData = cleanFormData(data)
    console.log(disbursementMethod)
    saveViaDisbursement({
      ...cleanData,
      ...{ idprogram: id },
      ...{ disbursementMethod: disbursementMethod },
    })
      .then(({ data: { data } }) => {
        navigate(`/disbursement/list`)
      })
      .catch(error => {
        console.error(error)
        toast.error(t => <ToasterError error={error} t={t} />)
      })
  }

  return (
    <Layouts.FormComponentLayout
      title="Agent Money Transfer"
      onSubmit={handleSubmit(kirim)}
      vas={[]}
      formFields={[
        <Controller
          name="agent_id"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Agent Id"
              placeholder="Masukkan Agent ID"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="account_number"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Nomor Rekening"
              placeholder="Masukkan nomor rekening"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="bank_code"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Bank"
              placeholder="Masukkan nama bank"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="direction"
          control={control}
          render={({ field, fieldState }) => (
            <SelectionField
              label="Jenis Transaksi"
              placeholder="Pilih jenis transaksi"
              options={[
                {
                  id: 'DOMESTIC_SPECIAL_TRANSFER',
                  name: 'DOMESTIC_SPECIAL_TRANSFER',
                },
                {
                  id: 'FOREIGN_INBOUND_SPECIAL_TRANSFER',
                  name: 'FOREIGN_INBOUND_SPECIAL_TRANSFER',
                },
              ]}
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="remark"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Remark"
              placeholder="Masukkan remark"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="amount"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Jumlah dana"
              placeholder="Masukkan jumlah dana"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="description"
          control={control}
          render={({ field, fieldState }) => (
            <InputField
              label="Description"
              placeholder="Masukkan description"
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,
        <Controller
          name="vendor_name"
          control={control}
          render={({ field, fieldState }) => (
            <SelectionField
              label="Vendor"
              placeholder="Masukkan Vendor Disbursement"
              options={[{ id: 'Flip', name: 'Flip' }]}
              fieldState={fieldState}
              {...field}
              isRequired={false}
            />
          )}
        />,

        // adiet ada tambahan 2 atribut baru ya
        // int quantity = ((Double) vmjExchange.getRequestBodyForm("quantity")).intValue();
        // int pricePerItem = ((Double) vmjExchange.getRequestBodyForm("price_per_item")).intValue();
      ]}
      itemsEvents={[
        <Button type="submit" variant="primary">
          Kirim
        </Button>,
      ]}
    />
  )
}

export default FormAgentMoneyTransfer
