/*
	Generated on 29/05/2024 by UI Generator PRICES-IDE
	https://amanah.cs.ui.ac.id/research/ifml-regen
	version 3.3.2
*/
import RequireAuth from 'commons/auth/RequireAuth'

import DaftarPGPage from './containers/DaftarPGPage'
import DaftarTarikDanaPage from './containers/DaftarTarikDanaPage'
import DetailPGPage from './containers/DetailPGPage'
import DetailTarikDanaPage from './containers/DetailTarikDanaPage'
import TambahPGPage from './containers/TambahPGPage'
import FormPilihPayment from './components/FormPilihPayment'
import FormPilihDisbursement from './components/FormPilihDisbursement'
import FormAgentMoneyTransfer from './components/FormAgentMoneyTransfer'
import FormInternationalMoneyTransfer from './components/FormInternationalMoneyTransfer'
import FormEWallet from './components/FormEWallet'

const paymentGatewayRoutes = [
  {
    path: '/viapaymentgateway/add',
    element: <TambahPGPage />,
  },
  {
    path: '/viapaymentgateway/detail/:id',
    element: <DetailPGPage />,
  },
  {
    path: '/viapaymentgateway/list',
    element: <DaftarPGPage />,
  },
  {
    path: '/viapaymentgateway/paymentchoice',
    element: <FormPilihPayment />,
  },
  {
    path: '/viapaymentgateway/disbursementchoice',
    element: <FormPilihDisbursement />,
  },
  {
    path: '/viapaymentgateway/agentmoneytransfer/add',
    element: <FormAgentMoneyTransfer />,
  },

  {
    path: '/viapaymentgateway/internationalmoneytransfer/add',
    element: <FormInternationalMoneyTransfer />,
  },
  {
    path: '/viapaymentgateway/ewallet/add',
    element: <FormEWallet />,
  },
  {
    path: '/disbursement/list',
    element: <DaftarTarikDanaPage />,
  },
  {
    path: '/disbursement/detail/:id',
    element: <DetailTarikDanaPage />,
  },
]

export default paymentGatewayRoutes
